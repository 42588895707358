import React, {useEffect, useState} from "react";
import {
    StratosAuditIcon,
    StratosDashboardIcon,
    StratosEndpointIcon,
    StratosIntegrationsIcon,
    StratosNetworkIcon,
    StratosSecurityIcon
} from "@cuda-react/icons";
import {PeopleAlt as PeopleAltIcon} from "@mui/icons-material";
import {NavigationChildRoute, NavigationRoute} from "@barracuda-internal/bds-core";
import {UnleashClient, useUnleashClient} from "@unleash/proxy-client-react";

export type InternalNavigationChildRoute = NavigationChildRoute & {
    /**
     * If present it will only be used if the feature with the same name is active.
     */
    feature?: string
}

export type InternalNavigationRoute = NavigationRoute & {
    /**
     * If present it will only be used if the feature with the same name is active.
     */
    feature?: string

    exact?: boolean;

    children?: InternalNavigationChildRoute[];
}

export const navigationDashboard = {
    exact: true,
    icon: <StratosDashboardIcon/>,
    path: "/:userData.currentAccount/:userData.currentWorkspace/",
    name: "stratos.navigation.dashboard"
};

export const navigationNetwork = {
    icon: <StratosNetworkIcon/>,
    path: "/:userData.currentAccount/:userData.currentWorkspace/infrastructure",
    name: "stratos.navigation.network.network",
    children: [
        {
            name: "stratos.navigation.network.gateways",
            path: "/:userData.currentAccount/:userData.currentWorkspace/infrastructure/gateways"
        },
        {
            name: "stratos.navigation.network.sites",
            path: "/:userData.currentAccount/:userData.currentWorkspace/infrastructure/sites"
        },
        {
            name: "stratos.navigation.network.iot",
            path: "/:userData.currentAccount/:userData.currentWorkspace/infrastructure/iot"
        },
        {
            name: "stratos.navigation.network.connectors",
            path: "/:userData.currentAccount/:userData.currentWorkspace/infrastructure/connectors"
        },
        {
            name: "stratos.navigation.network.vmActivation",
            path: "/:userData.currentAccount/:userData.currentWorkspace/infrastructure/vmactivation",
        },
        {
            name: "stratos.navigation.network.locations",
            path: "/infrastructure/locations",
            feature: "dnslocations"
        },
        {
            name: "stratos.navigation.network.settings.settings",
            children: [
                {
                    name: "stratos.navigation.network.settings.general",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/infrastructure/settings/general",
                },
                {
                    name: "stratos.navigation.network.settings.connector",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/infrastructure/settings/connector"
                },
                {
                    name: "stratos.navigation.network.settings.updateWindows",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/infrastructure/settings/updatewindows"
                }
            ]
        },
    ]
};

export const navigationTrinity = {
    icon: <StratosEndpointIcon/>,
    path: "/:userData.currentAccount/:userData.currentWorkspace/ztna",
    name: "stratos.navigation.ztna.ztna",
    children: [
        {
            name: "stratos.navigation.ztna.users",
            path: "/:userData.currentAccount/:userData.currentWorkspace/ztna/users"
        },
        {
            name: "stratos.navigation.ztna.devices",
            path: "/:userData.currentAccount/:userData.currentWorkspace/ztna/devices"
        },
        {
            name: "stratos.navigation.ztna.pointsOfEntry",
            path: "/:userData.currentAccount/:userData.currentWorkspace/ztna/pointsofentry"
        },
        {
            name: "stratos.navigation.ztna.appCatalog",
            path: "/:userData.currentAccount/:userData.currentWorkspace/ztna/appcatalog"
        },
        {
            name: "stratos.navigation.ztna.settings",
            path: "/:userData.currentAccount/:userData.currentWorkspace/ztna/settings"
        }
    ]
};

export const navigationIdentity = {
  icon: <PeopleAltIcon />,
  path: '/identity',
  name: 'stratos.navigation.identity.identity',
  children: [
    {
        name: 'stratos.navigation.identity.users',
        path: '/identity/users',
    },
    {
        name: 'stratos.navigation.identity.groups',
        path: '/identity/groups',
    },
    {
      name: 'stratos.navigation.identity.settings',
      path: '/identity/settings',
    },
  ],
};


export const navigationSecurity = {
    icon: <StratosSecurityIcon/>,
    name: "stratos.navigation.securityPolicy.securityPolicy",
    path: "/:userData.currentAccount/:userData.currentWorkspace/policies",
    children: [
        {
            name: "stratos.navigation.securityPolicy.apps",
            path: "/:userData.currentAccount/:userData.currentWorkspace/policies/apps"
        },
        {
            name: "stratos.navigation.securityPolicy.network.network",
            children: [
                {
                    name: "stratos.navigation.securityPolicy.network.gateway",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/network/gateway"
                },
                {
                    name: "stratos.navigation.securityPolicy.network.site",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/network/site"
                },
                {
                    name: "stratos.navigation.securityPolicy.network.ingressNatRules",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/network/ingress"
                }
            ]
        },
        {
            name: "stratos.navigation.securityPolicy.sdwan.sdwan",
            children: [
                {
                    name: "stratos.navigation.securityPolicy.sdwan.applicationCategories",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/sdwan/categories"
                },
                {
                    name: "stratos.navigation.securityPolicy.sdwan.overrideCategories",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/sdwan/explicit"
                }
            ]
        },
        {
            name: "stratos.navigation.securityPolicy.webFilter.webFilter",
            children: [
                {
                    name: "stratos.navigation.securityPolicy.webFilter.urlFilter",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/webfilter/urlfilter"
                },
                {
                    name: "stratos.navigation.securityPolicy.webFilter.customCategories",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/webfilter/customcategories"
                },
                {
                    name: "stratos.navigation.securityPolicy.webFilter.webMonitorPolicies",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/webfilter/policies"
                },
                {
                    name: "stratos.navigation.securityPolicy.webFilter.settings",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/webfilter/settings"
                }
            ]
        },
        {
            name: "stratos.navigation.securityPolicy.security.security",
            children: [
                {
                    name: "stratos.navigation.securityPolicy.security.advThreatProtection",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/security/malware"
                },
                {
                    name: "stratos.navigation.securityPolicy.security.ssl",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/security/ssl"
                },
                {
                    name: "stratos.navigation.securityPolicy.security.ips",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/security/ips"
                }
            ]
        },
        {
            name: "stratos.navigation.securityPolicy.ztna.ztna",
            children: [{
                name: "stratos.navigation.securityPolicy.ztna.zeroTrustAccess",
                path: "/:userData.currentAccount/:userData.currentWorkspace/policies/ztna/zerotrustaccess"
            }]
        },
        {
            name: "stratos.navigation.securityPolicy.settings.settings",
            children: [
                {
                    name: "stratos.navigation.securityPolicy.settings.customResponse",
                    path: "/:userData.currentAccount/:userData.currentWorkspace/policies/settings/customresponse"
                }
            ]
        }
    ]
};
export const navigationAudit: InternalNavigationRoute = {
    exact: true,
    icon: <StratosAuditIcon/>,
    name: "stratos.navigation.logs.logs",
    path: "/:userData.currentAccount/:userData.currentWorkspace/logs",
    children: [
        {
            name: "stratos.navigation.logs.audit",
            path: "/:userData.currentAccount/:userData.currentWorkspace/logs/audit",
        },
        {
            name: "stratos.navigation.logs.notifications",
            path: "/:userData.currentAccount/:userData.currentWorkspace/logs/notifications",
        },
        {
            name: "stratos.navigation.logs.syslog",
            path: "/:userData.currentAccount/:userData.currentWorkspace/logs/syslog"
        },
        {
            name: "stratos.navigation.logs.reports",
            path: "/:userData.currentAccount/:userData.currentWorkspace/logs/reports",
            feature: "reports"
        },
    ]
};
export const navigationIntegration = {
    icon: <StratosIntegrationsIcon/>,
    name: "stratos.navigation.integration.integration",
    path: "/:userData.currentAccount/:userData.currentWorkspace/integration",
    children: [
        {
            name: "stratos.navigation.integration.firewalls",
            path: "/:userData.currentAccount/:userData.currentWorkspace/integration/firewalls"
        },
        {
            name: "stratos.navigation.integration.settings",
            path: "/:userData.currentAccount/:userData.currentWorkspace/integration/settings",
        },
        {
            name: "stratos.navigation.integration.xdr",
            path: "/:userData.currentAccount/:userData.currentWorkspace/integration/xdr"
        },
        {
            name: "stratos.navigation.integration.ipsecV2",
            path: "/:userData.currentAccount/:userData.currentWorkspace/integration/ipsecV2"
        }
    ]
};


export const navigation: InternalNavigationRoute[] = [
    navigationDashboard,
    navigationNetwork,
    navigationTrinity,
    navigationSecurity,
    navigationIdentity,
    navigationAudit,
    navigationIntegration,
];

export type FeatureRoute = {feature? : string; children?: FeatureRoute[]};

/**
 * Filter out routes and children routes which the feature isn't enabled.
 */
export const applyFeatureRoute = (routes: FeatureRoute[], unleashClient: UnleashClient) => routes.reduce((result, route) => {
        if (route.feature) {
            const isActive = unleashClient.isEnabled(route.feature);

            if (!isActive) {
                return result;
            }
        }

        const innerRoute = {...route};
        // check if the children has features
        if (innerRoute.children) {
            innerRoute.children = applyFeatureRoute(innerRoute.children, unleashClient);
        }

        return [...result, innerRoute];
    }, [] as FeatureRoute[]);

export const useNavigationRoutes = (): NavigationRoute[] => {
    const unleashClient = useUnleashClient();
    const [activeRoutes, setActiveRoutes] = useState<NavigationRoute[]>([]);

    useEffect(() => {
        const updateCallback = () => {
            setActiveRoutes(applyFeatureRoute(navigation, unleashClient) as NavigationRoute[]);
        };
        unleashClient.on("update", updateCallback);
        updateCallback();
        return () => {
            unleashClient.off("update", updateCallback);
        };
    }, [unleashClient]);

    return activeRoutes;
};