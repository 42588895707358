import React, {useRef} from "react";
import {
    ActionButtonsField,
    ChipArrayField,
    ConnectedTable,
    ConnectedTableRefresh,
    CrudTypes,
    DeleteDialog,
    getArrayDataContent,
    PropGateway,
    StatusIconField,
    TextField,
    useCrudFetch,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {makeStyles} from "@mui/styles";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditUrlExceptions from "./createEdit/CreateEditUrlExceptions";
import {useSourceDestinationField} from "../../../../../hooks/sourceAndDestinationHooks";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import {
    formatSourceDestinationFilters,
    formatUserGroupInTableData
} from "../../../../../components/sourceAndDestination/tableUtils";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import {settingsIcons} from "../../../../../components/iconMapping";
import apiResources from "../../../../../apiResources";
import {Theme} from "@mui/material";
import {get} from "lodash";
import GroupingField from "../../../networkAccess/tabs/GroupingField";

export const styles = (theme: Theme) => ({
    cardActions: {
        paddingRight: theme.spacing(2),
        width: "calc(100% - " + theme.spacing(2) + ")"
    },
    orderCell: {
        minWidth: 70
    },
    actionCell: {
        minWidth: 70,
        paddingLeft: 0
    }
});
const useStyles = makeStyles(styles);

export interface ExplicitTableProps {
    actionChoices: any[],
    categoryChoices: any[]
}

export const ExplicitTable = ({actionChoices, categoryChoices}: ExplicitTableProps) => {
    const registerAction = useMixpanel("Web Filter", false);
    const explicitTableRefreshRef = useRef<ConnectedTableRefresh | null>(null);
    const [, , callUrlExceptions] = useCrudFetch(CrudTypes.CREATE, apiResources.urlExceptions, {});
    const classes = useStyles();
    const handleExceptionsOrderClick = (id: string, direction: string, data: any) => callUrlExceptions({
        id,
        changeOrder: direction,
        data
    }).then(explicitTableRefreshRef.current);
    const generateChipArrayProps = useSourceDestinationField(categoryChoices);
    const customCategories = getArrayDataContent(useCrudProps(apiResources.customCategories)[0]?.data);

    return (
        <ConnectedTable
            resource={apiResources.urlExceptions}
            exportResource={{resource: apiResources.urlExceptionsExport, filename: "urlExceptions"}}
            actions={
                <CreateEditButtonDialog
                    create component={CreateEditUrlExceptions}
                    onSuccess={() => explicitTableRefreshRef.current?.()}
                />
            }
            params={(params) => {
                const formattedParams = formatSourceDestinationFilters(params);
                return {
                    ...formattedParams,
                    filter: {
                        ...(formattedParams.filter || {}),
                        rules: undefined,
                        rule: [...((params.filter && params.filter.rules && params.filter.rules.category || []))],
                        category: params.filter && params.filter.rules && params.filter.rules.category || [],
                        destinationType: params.filter && params.filter.rules && params.filter.rules.type || [],
                        domain: params.filter && params.filter.rules && params.filter.rules.domain || []
                    }
                };
            }}
            classes={{cardActions: classes.cardActions}}
            groupBy="scope"
            groupField={
                <GroupingField
                    createEditComponent={CreateEditUrlExceptions}
                    render={(value: string) => value === "siteEdgeService" ? "tesseract.security.urlFiltering.exceptions.siteEdgeService" : "tesseract.security.urlFiltering.exceptions.dnsLocation"}
                    // @ts-ignore
                    onSuccess={explicitTableRefreshRef.current}
                    noIcon
                />
            }
            formatData={formatUserGroupInTableData}
            minCellWidth={128}
            refreshRef={explicitTableRefreshRef}
            flat
        >
            <ActionButtonsField
                left
                label="tesseract.security.urlFiltering.settings.order"
                cellClassName={classes.orderCell}
                width={96}
                source="id"
            >
                <TextField
                    source="order"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowUpwardIcon
                    onClick={(event: any, data: any) => handleExceptionsOrderClick(data.id, "increase", data)}
                    disabled={(data: any) => get(data, "order", 0) < 2}
                    id="cuda-icon-up"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowDownwardIcon
                    onClick={(event: any, data: any) => handleExceptionsOrderClick(data.id, "decrease", data)}
                    disabled={(data: any) => (get(data, "groupTotal", 0) - get(data, "order", 0)) < 1}
                    id="cuda-icon-down"
                />
            </ActionButtonsField>
            <TextField
                source="name"
                label="tesseract.security.urlFiltering.exceptions.name"
                filter="text"
            />
            <TextField
                source="description"
                label="tesseract.security.urlFiltering.exceptions.description"
                filter="text"
            />
            <StatusIconField
                source="action"
                text={(action) => (actionChoices.find((item) => item.key === action) || {}).name}
                label="tesseract.security.urlFiltering.exceptions.action"
                cellClassName={classes.actionCell}
                iconMap={settingsIcons}
                filter="select"
                filterProps={{
                    choices: actionChoices
                }}
                width={140}
            />
            <PropGateway
                source="source"
                label="tesseract.security.urlFiltering.exceptions.source"
                editProps={generateChipArrayProps("source")}
                filter="custom"
                filterProps={{
                    component: SourceFilter,
                    filterSources: ["network", "site", "userOrGroup", "location"]
                }}
            >
                <ChipArrayField source="source"/>
            </PropGateway>
            <PropGateway
                source="destination"
                label="tesseract.security.urlFiltering.exceptions.destinations"
                editProps={generateChipArrayProps("destination")}
                filter="custom"
                filterProps={{
                    source: "rules",
                    component: DestinationFilter,
                    filterSources: ["domain", "category", "customCategory"],
                    table: "WebFilterExplicit",
                    categoryChoices,
                    customCategories
                }}
            >
                <ChipArrayField source="destination"/>
            </PropGateway>
            <ActionButtonsField
                width={96}
                source="id"
            >
                <CreateEditButtonDialog
                    component={CreateEditUrlExceptions}
                    onSuccess={() => explicitTableRefreshRef.current?.()}
                />
                <DeleteDialog
                    resource={apiResources.urlExceptions}
                    title="tesseract.security.urlFiltering.settings.delete.title"
                    message="tesseract.security.urlFiltering.settings.delete.body"
                    onSuccess={() => {
                        registerAction("Explicit Delete");
                        explicitTableRefreshRef.current?.();
                    }}
                />
            </ActionButtonsField>
        </ConnectedTable>
    );
};

export default ExplicitTable;