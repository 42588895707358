import React from "react";
import {IntercomProvider} from "react-use-intercom";
import useUpdatesBannerNotification from "../hooks/useUpdatesBannerNotification";
import useSubscriptionsBannerNotification from "../hooks/useSubscriptionsBannerNotification";
import useLicensesBannerNotification from "../hooks/useLicensesBannerNotification";

const GlobalStatusController = () => {
    useUpdatesBannerNotification();
    useSubscriptionsBannerNotification();
    useLicensesBannerNotification();

    const intercomDisabled = document.cookie.includes("DISABLE_INTERCOM=true");
    return intercomDisabled ? null : (
        // @ts-ignore
        <IntercomProvider
            appId="idva4pea"
            autoBoot
            // Footer height + 20
            autoBootProps={{verticalPadding: 61}}
        />
    );
};

export default GlobalStatusController;