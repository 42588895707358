// AzureLogAnalyticsOMS.tsx
import React from "react";
import {BooleanInput, TextInput, PasswordConfirmInput} from "@cuda-react/core";
import {get} from "lodash";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    azureMonitorAgentForm: {
        marginBottom: theme.spacing(4)
    }
}));

const AzureLogAnalyticsOMS = () => {
    const classes = useStyles();

    return (
        <div className={classes.azureMonitorAgentForm}>
            <TextInput
                source="settings.oms_workspace.id"
                label="tesseract.settings.tabs.oms.id"
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
            <PasswordConfirmInput
                label="tesseract.settings.tabs.oms.key"
                source="settings.oms_workspace.key"
                validate={[]}
                noConfirm
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
        </div>
    );
};

export default AzureLogAnalyticsOMS;