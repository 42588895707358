import React from "react";
import {makeStyles} from "@mui/styles";
import CreateEditButtonDialog from "../../../../components/CreateEditButtonDialog";
import {SelectedChoiceField} from "@cuda-react/core";
import SingleSiteIcon from "@mui/icons-material/StoreMallDirectory";
import {GatewayIcon, SiteIcon} from "@cuda-react/icons";
import {get} from "lodash";

const useStyles = makeStyles({
    root: {
        display: "inline-flex",
        width: "calc(100% - 24px)",
        justifyContent: "space-between"
    }
});

interface GroupingFieldProps {
    source?: string | any;
    data?: Record<string, any>;
    type?: string;
    choices?: object[];
    createEditComponent: React.ComponentType<any>;
    dataIconType?: string;
    onSuccess?: () => void;
    noIcon?: boolean;
}

export const GroupingField: React.FC<GroupingFieldProps> = (props) => {
    const {source, data, type, createEditComponent, dataIconType, onSuccess, noIcon} = props;
    const classes = useStyles(props);
    const iconType = dataIconType ? get(data, `items[0]${dataIconType}`) : type;
    const isGateway = iconType === "gateway" || iconType === "onPremGateway";
    const value = get(data, source);

    return (
        <div className={classes.root}>
            <SelectedChoiceField
                {...props}
                optionIcon="uuid"
                iconMap={isGateway ? undefined : {allSites: <SiteIcon/>}}
                icon={!noIcon ? (isGateway ? <GatewayIcon/> : <SingleSiteIcon/>) : undefined}
            />
            <CreateEditButtonDialog
                create
                component={createEditComponent}
                defaultScope={value}
                type={iconType}
                onSuccess={onSuccess}
            />
        </div>
    );
};

export default GroupingField;