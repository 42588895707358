import React from "react";
import {LocationsCardContent, CustomField, getArrayDataContent, LinkField, SelectedChoiceField, useGlobalParam, useCrudProps} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {useTheme} from "@mui/material";
import {BaseCard, DashboardCardProps} from "@stratos/reporting";
import {getAzureHubLink, getAzureResourceLink} from "../../../../utils/azureUtils";
export interface IoTTunnelLocationsCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id?: number | string,
        }
    }
}

export const IoTTunnelLocationsCard = (props: IoTTunnelLocationsCardProps) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
     const isDemo = useGlobalParam("userData.mode")[0] === "demo";
     const regionChoices = getArrayDataContent(useCrudProps(apiResources.gatewayRegions)[0]?.data);
    const scaleUnitChoices = getArrayDataContent(useCrudProps(apiResources.gatewayScaleUnits)[0]?.data);

    const theme = useTheme();
    return (
        <BaseCard
            id="IoTTunnelLocationsCard"
            title="tesseract.appliances.dashboard.tunnelLocations.title"
            size={size}
            preview={preview}
        >
            <LocationsCardContent
                params={{id: details?.id}}
                resource={apiResources.siteTunnelLocations}
                series={[
                    {
                         label: "tesseract.appliances.dashboard.tunnelLocations.gateways.label",
                        color: theme.palette.general.purple,
                        zIndex: 4,
                        linkedSeries: {
                            color: theme.palette.general.darkBlue,
                            zIndex: 3,
                            selectedZIndex: 5
                        },
                        details: {
                            resource: apiResources.gateways,
                            optionValue: "id",
                            fields: [
                                <LinkField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.name"
                                    source="name"
                                    key="name"
                                    link={getAzureResourceLink("applicationId", isDemo)}
                                />,
                                <CustomField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.sites"
                                    source="tseries"
                                    key="tseries"
                                    render={(sites, data) => (
                                        <LinkField
                                            defaultText={(sites?.length) || sites}
                                            link={`/infrastructure/sites?filter={"gateway":[${data.id}]}`}
                                        />
                                    )}
                                />,
                                <CustomField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.iot"
                                    source="iot"
                                    key="iot"
                                    render={(sites: string | any[], data: { id: any; }) => (
                                        <LinkField
                                            defaultText={(sites?.length) || sites}
                                            link={`/infrastructure/iot?filter={"gateway":[${data.id}]}`}
                                        />
                                    )}
                                />,
                                <LinkField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.vwan"
                                    source="virtualWan.name"
                                    key="vwanName"
                                    link={getAzureResourceLink("virtualWan.resourceId", isDemo)}
                                />,
                                <LinkField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.hub"
                                    source="hubName"
                                    key="hubName"
                                    link={getAzureHubLink("hubId", isDemo)}
                                />,
                                <SelectedChoiceField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.region"
                                    source="region"
                                    key="region"
                                    choices={getArrayDataContent(regionChoices)}
                                />,

                                <SelectedChoiceField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.size"
                                    source="scaleUnit"
                                    key="scaleUnit"
                                    choices={getArrayDataContent(scaleUnitChoices)}
                                    render={(value: any, data: any, choices: { key: any; name: any; }) => choices.key === value ? value = (
                                                                   choices.name) : (value)}
                                />
                            ]
                        }
                    },
                    {
                        label: "tesseract.appliances.dashboard.tunnelLocations.iot.label",
                        color: theme.palette.general.turquoise,
                        source: "sites"
                    }
                ]}
            />
        </BaseCard>
    );
};