import {makeStyles} from "@mui/styles";
import {
    ConnectedForm,
    DialogBody,
    FormSection,
    getArrayDataContent,
    ipv4CIDRRegex,
    ipv4Regex,
    MultiInput,
    SelectInput,
    TextArrayInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    useMixpanel,
    validateArray,
    validateInt,
    validateMaxLengthMemo,
    validateMaxValueMemo,
    validateMinValueMemo,
    validateNegativeRegex,
    validateRegexMemo,
    validateWildcardDomain
} from "@cuda-react/core";
import React, {useState} from "react";
import {categoryIcons} from "../../../../components/iconMapping";
import {get, set} from "lodash";
import apiResources from "../../../../apiResources";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";

export const useStyles = makeStyles((theme) => ({
    readOnlyField: {
        width: 255
    },
    portRangeWarning: {
        padding: "15px 30px",
        color: theme.palette.warning.main,
    }
}));

/**
 * For now we are using this workaround to get the karma tests working.
 */
interface CustomAppContentProps {
    title?: string;
    create?: boolean;
    id?: number | string;
    onClose?: () => void;
    onSuccess?: () => void;
    type?: "network" | "web";
    label?: string;
}

export const CustomAppContent: React.FC<CustomAppContentProps> = ({
                                                                      create,
                                                                      id,
                                                                      title,
                                                                      type,
                                                                      onSuccess,
                                                                      ...props
                                                                  }) => {
    const registerAction = useMixpanel("Apps", false);
    const choicesData = useCrudProps(apiResources.sdwanPolicies)[0]?.data;
    const [translate] = useTranslation();
    // @ts-ignore
    const protocolData = useCrudProps(apiResources.protocol, {}, {cache: true})[0]?.data;
    const validations = {
        port: [validateInt, validateMaxValueMemo(65535), validateMinValueMemo(1)],
        name: [validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")],
        description: [validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")],
        wildcardDomain: [
            validateArray([
                validateWildcardDomain,
                validateNegativeRegex(ipv4Regex, "cuda.validation.wildcardDomain"),
                validateNegativeRegex(ipv4CIDRRegex, "cuda.validation.wildcardDomain")
            ])
        ]
    };
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [showWarning, setShowWarning] = useState(false);
    const classes = useStyles();


    return (
        <DialogBody
            {...props}
            form
            title={title}
        >
            <ConnectedForm
                create={create}
                flat
                resource={apiResources.customApps}
                id={id}
                // @ts-ignore
                form="custom-app"
                onSubmitSuccess={(response) => {
                    onSuccess?.();
                    // @ts-ignore
                    props.onClose();
                    // @ts-ignore
                    registerAction(create ? "Create" : "Update", {type, category: response?.categoryId});
                }}
                onCancel={props.onClose}
                initialValues={type && {type} || undefined}
                formatRequestData={(data) => ({
                    ...data,
                    destinations: data.type === "web" ? data.destination && data.destination.map((destination: any) => ({destination})) : data.destinations,
                    virtualWanId: create ? virtualWanId : data.virtualWanId
                })}
                formatResourceData={(data) => ({
                    ...data,
                    destination: data.type === "web" ? data.destinations && data.destinations.map((destination: {
                        destination: any;
                    }) => destination.destination) : [],
                    destinations: data.type === "web" ? [] : data.destinations
                })}
                formatError={(error, data) => {
                    set(error, "destination", data.type === "web" ? get(error, "destinations")?.map((val: {
                        destination: any;
                    }) => val.destination) : undefined);
                    set(error, "destinations", data.type === "web" ? undefined : get(error, "destinations"));
                    return error;
                }}
                onChange={(data) => {
                    // Ensure destinations is always an array
                    const destinations = data.destinations || [];
                    // Use .some() to check for warning conditions
                    const portWarning = destinations.some((destination: { firstPort: string; lastPort: string; }) => {
                        // No ports set, which means all ports, so set warning
                        const firstPort:any= destination.firstPort ? parseInt(destination.firstPort, 10) : null;
                        const lastPort: any= destination.lastPort ? parseInt(destination.lastPort, 10) : null;
                        if (!destination.firstPort && !destination.lastPort) {
                            return true;
                        }
                        // First port set only, so set warning if it's an exact match for 80/443
                        if (
                            !destination.lastPort &&
                            (destination.firstPort === "80" || destination.firstPort === "443")
                        ) {
                            return true;
                        }
                        // Else check if port range covers 80/443
                        return(
                            (firstPort <= 80 && lastPort >= 80) ||
                            (firstPort <= 443 && lastPort >= 443)
                        );
                    });
                    // Set the warning state
                    setShowWarning(portWarning);
                }}
                allowDirtyNavigation
            >
                <FormSection title="tesseract.customApps.dialogs.general.sectionTitle">
                    <TextInput
                        source="name"
                        label="tesseract.customApps.dialogs.general.name"
                        description="tesseract.customApps.dialogs.general.descriptions.name"
                        isRequired
                        validate={validations.name}
                    />
                    <TextInput
                        source="description"
                        label="tesseract.customApps.dialogs.general.description"
                        description="tesseract.customApps.dialogs.general.descriptions.description"
                        validate={validations.description}
                    />
                </FormSection>
                <FormSection title="tesseract.customApps.dialogs.endpoints.sectionTitle">
                    <TextInput
                        key="protocol"
                        source="protocol"
                        label="tesseract.customApps.dialogs.endpoints.protocol"
                        description="tesseract.customApps.dialogs.endpoints.descriptions.protocol"
                        hide={(value, allValues) => !allValues || allValues.type !== "web"}
                        // @ts-ignore
                        value="HTTP/HTTPS"
                        disabled
                        className={classes.readOnlyField}
                    />
                    <TextInput
                        key="port"
                        source="port"
                        label="tesseract.customApps.dialogs.endpoints.port"
                        description="tesseract.customApps.dialogs.endpoints.descriptions.port"
                        hide={(value, allValues) => !allValues || allValues.type !== "web"}
                        // @ts-ignore
                        value="80/443"
                        disabled
                        className={classes.readOnlyField}
                    />
                    <TextArrayInput
                        key="destination"
                        source="destination"
                        label="tesseract.customApps.dialogs.endpoints.destination"
                        description="tesseract.customApps.dialogs.endpoints.descriptions.webAppDestination"
                        isRequired
                        hide={(value, allValues) => !allValues || allValues.type !== "web"}
                        validate={validations.wildcardDomain}
                    />
                    <MultiInput
                        maxInputs={250}
                        source="destinations"
                        hide={(value, allValues) => allValues && allValues.type === "web"}
                    >
                        <SelectInput
                            source="protocol"
                            label="tesseract.customApps.dialogs.endpoints.protocol"
                            description="tesseract.customApps.dialogs.endpoints.descriptions.protocol"
                            choices={getArrayDataContent(protocolData)}
                            isRequired
                        />
                        <TextInput
                            source="destination"
                            label="tesseract.customApps.dialogs.endpoints.destination"
                            description="tesseract.customApps.dialogs.endpoints.descriptions.destination"
                            isRequired
                        />
                        <TextInput
                            source="firstPort"
                            label="tesseract.customApps.dialogs.endpoints.firstPort"
                            description="tesseract.customApps.dialogs.endpoints.descriptions.firstPort"
                            validate={validations.port}
                        />
                        <TextInput
                            source="lastPort"
                            label="tesseract.customApps.dialogs.endpoints.lastPort"
                            description="tesseract.customApps.dialogs.endpoints.descriptions.lastPort"
                            validate={validations.port}
                            disable={(fieldValue, data, sourcePrefix, siblingData) => !(siblingData && (siblingData.firstPort || fieldValue))}
                        />
                    </MultiInput>
                    {showWarning && <Typography
                        className={classes.portRangeWarning}>{translate("tesseract.customApps.dialogs.endpoints.error.portRangeError")}</Typography>}

                </FormSection>
                <FormSection title="tesseract.customApps.dialogs.category">
                    <SelectInput
                        source="categoryId"
                        label="tesseract.customApps.dialogs.category"
                        description="tesseract.customApps.dialogs.descriptions.category"
                        choices={getArrayDataContent(choicesData)}
                        iconMap={categoryIcons}
                        optionValue="category"
                        // @ts-ignore
                        optionIcon="category"
                        defaultChoice="saasAndBusiness"
                        isRequired
                    />
                </FormSection>
            </ConnectedForm>
        </DialogBody>
    );
};


// CustomAppContent.defaultProps = {
//     onClose: () => {
//     }
// };

export default CustomAppContent;