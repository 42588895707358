import React from "react";
import UrlFilters from "./tabs/UrlFilters/UrlFilters";
import WebFilterSettings from "./settings/WebFilterSettings";
import {Route, Switch} from "react-router";
import CustomCategories from "./tabs/CustomCategories/CustomCategories";
import WebMonitorPolicies from "./webMonitorPolicies/WebMonitorPolicies";

const WebFilter = () => (
    <Switch>
        <Route path="/:account/:workspace/policies/webfilter/customcategories">
            <CustomCategories />
        </Route>
        <Route path="/:account/:workspace/policies/webfilter/urlfilter">
            <UrlFilters />
        </Route>
        <Route path="/:account/:workspace/policies/webfilter/settings">
            <WebFilterSettings />
        </Route>
        <Route path="/:account/:workspace/policies/webfilter/policies">
            <WebMonitorPolicies />
        </Route>
    </Switch>
);

export default WebFilter;