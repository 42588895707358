import {AuthEventType, loginAppAuthClient} from "@cuda-react/core";
import {get, merge} from "lodash";

export const SUBSCRIPTION_URL = "/api/v1/webui/%account%/subscriptions";

export type GlobalParams = { [key: string]: any } & { origin?: string, previousOrigin?: string };

const getPostPurchaseUrl = (result?: any, globalParams?: GlobalParams) => {
    if (result?.redirect?.includes("post_purchase?")) {
        return "/post_purchase" + result.redirect.substring(result.redirect.indexOf("?"));
    } else if (globalParams?.origin && globalParams.origin.includes("post_purchase?")) {
        return "/post_purchase" + globalParams.origin.substring(globalParams.origin.indexOf("?"));
    } else if (globalParams?.previousOrigin && globalParams?.previousOrigin.includes("post_purchase?")) {
        return "/post_purchase" + globalParams.previousOrigin.substring(globalParams.previousOrigin.indexOf("?"));
    }
};

export default (type: AuthEventType, params: any, globalParams: GlobalParams): Promise<any> => {
    if (type === AuthEventType.CHECK && !params && (!get(globalParams, "userData.subscription.setUpComplete") || !get(globalParams, "userData.subscription.licensed"))) {
        return Promise.reject();
    }

    if (type === AuthEventType.LOGOUT && get(globalParams, "userData.mode") === "demo") {
        return Promise.resolve({redirect: "/login"});
    }

    const responsePromise = loginAppAuthClient(type, params, globalParams);

    if (type === AuthEventType.CHANGE_ACCOUNT && get(params, "updateInPlace")) {
        return responsePromise.then((result: any) => fetch(SUBSCRIPTION_URL.replace("%account%", get(result, "userData.currentAccount", "")), {credentials: "include", headers: {"Authorization": "Bearer "+ globalParams?.auth0?.idToken}})
            .then((response) => response.json().then((subscription) => merge({}, result, {userData: {subscription: subscription || null}}))))
            .catch(() => ({
                redirect: "/login",
                userData: null,
                auth0: {idToken: null, accessToken: null, stsToken: null}
            }));
    }

    if (type === AuthEventType.LOGIN) {
        return responsePromise.then((result: any) =>
            fetch(SUBSCRIPTION_URL.replace("%account%", get(result, "userData.currentAccount", "")), {credentials: "include", headers: {"Authorization": "Bearer " + result?.auth0?.stsToken?.access_token}})
                .then((response) => response.json().then(
                    (subscription) => {
                        if (response.status === 403) {
                            return {...result, redirect: "/unauthorized"};
                        }
                        const postPurchaseRedirect = getPostPurchaseUrl(result, globalParams);
                        const subscribing = postPurchaseRedirect && postPurchaseRedirect.includes("serial");
                        const redirect = get(result, "redirect", "/");
                        // Always redirect subscribing attempts to subscribe
                        if (subscribing) {
                            return merge({}, result, {redirect: postPurchaseRedirect, userData: {subscription}});
                        }

                        // Always redirect unsubscribed users according to welcome.
                        if (response.status !== 200 || !subscription?.setUpComplete) {
                            return merge({}, result, {redirect: "/welcome", userData: {subscription}});
                        }

                        // Redirect expired subscription users to expired
                        if (!subscription?.licensed) {
                            return merge({}, result, {redirect: "/expired", userData: {subscription}});
                        }

                        // Redirect deprecated subscription users to deprecated if deprecated is true
                        if (subscription?.deprecated) {
                            return merge({}, result, {redirect: "/deprecated", userData: {subscription}});
                        }

                        // Return fully authenticated and subscribed users to their destination
                        // (except for the 'bad' sub urls, which the user may have just switched account from)
                        return merge({}, result, {
                            redirect: ["/welcome", "/post_purchase", "/expired", "/deprecated", "/unauthorized"].includes(redirect) ? "/" : redirect,
                            userData: {subscription}
                        });
                    },
                    () => merge({}, result, {redirect: "/post_purchase", userData: {subscription: null}})
                )));
    }

    return responsePromise;
};